import React, { useState } from 'react';
import Cookies from 'js-cookie';

import { RiCarLine, RiMotorbikeLine, RiCarFill } from 'react-icons/ri';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import paymentMethod1 from '../img/fpx-logo.png';
import paymentMethod2 from '../img/atome-logo.png';
import paymentMethod3 from '../img/shopeepay-logo.png';
import paymentMethod4 from '../img/grabpay-logo.jpg';
import paymentMethod5 from '../img/visa-mastercard.png';
import SendEmail from './SendEmail';

function Hero() {
  const [selectedVehicle, setSelectedVehicle] = useState('car');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [formData, setFormData] = useState({
    // vehicle_type: 'car',
    // nama: 'Akmal',
    // id_type: '1',
    // id_number: '911119146881',
    // plate_no: 'wvy1664',
    // email: 'khaiakmals@gmail.com',
    // whatsapp_no: '0172284869',
    // status: 'new',
    vehicle_type: 'car',
    nama: '',
    id_type: '1',
    id_number: '',
    plate_no: '',
    email: '',
    whatsapp_no: '',
    status: 'new',
  });

  const to = 'kasirenew@gmail.com';
  const subject = `Permintaan Sebut Harga - ${formData.plate_no}`;
  // const text = `Permintaan sebut harga baharu untuk kenderaan ABC1234.`;
  const text = `Log Masuk ke admin panel untuk menghantar sebut harga.`;

  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value);
    setFormData({ ...formData, vehicle_type: event.target.value });
  };

  const navigate = useNavigate();

  const constructWhatsAppLink = () => {
    const message = `Permintaan Sebut Harga KasiRenew.com:\nNama: ${formData.nama}\nPlate No: ${formData.plate_no}\n`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/601120888930?text=${encodedMessage}`;
    return whatsappLink;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Get the 'ref' value from the cookie
      const refValue = Cookies.get('ref');
  
      // Set 'direct' as the 'referral' value if 'ref' is empty
      const referralValue = refValue || 'direct';
  
      // Add 'referral' to the form data
      const updatedFormData = { ...formData, referral: referralValue };
  
      const response = await axios.post('https://api.kasirenew.com/api/clients', updatedFormData);
  
      if (response.status === 201) {
        SendEmail({ to, subject, text });
        const whatsappLink = constructWhatsAppLink();
        window.location.href = whatsappLink;
        navigate('/thank-you');
      } else {
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('An error occurred while submitting the form.', error);
    }
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowForm = () => {
    setShowForm(true);
    setTimeout(() => setDisplayForm(true), 500); // 500ms to match the transition duration
  };

  return (
    <section className="mt-10 bg-purple-500 py-16 px-8 md:px-16 lg:px-24 xl:px-32">
      {loading && (<div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
        <RiCarFill className="text-4xl text-purple-600 animate-spin" />
      </div>)}
      
      <div className='flex justify-center w-full text-center'>
        <div className="md:w-3/5 text-white mb-2">
          <h1 className="text-6xl md:text-6xl font-bold mb-4">Renew Takaful Kenderaan <span className='text-yellow-200'>Harga Telus</span></h1>
          <p className="text-lg md:text-xl mb-8">Harga terus dari syarikat takaful. Tanpa markup.</p>
        </div>
      </div>
      {!displayForm && (<div className="py-12">
        <div
          className={`max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 transition-all duration-500 ease-in-out transform ${
            showForm ? 'opacity-0 scale-95 pointer-events-none' : 'opacity-100 scale-100'
          }`}
        >
          {/* KasiRenew BNPL */}
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h3 className="text-2xl font-semibold text-center text-purple-500 mb-4">KasiRenew <span className='font-bold text-red-400'>BNPL</span></h3>
            <p className="text-center text-gray-600 mb-6">Tengah sesak? Boleh renew dahulu, bayar bulan depan atau secara ansuran</p>
            <ul className="space-y-3 text-gray-700">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Boleh bayar secara ansuran
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Harga telus tanpa markup
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Support pembayaran SPayLater, Grab PayLater & Boost PayFlex. Atome tak support buat masa ini.
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Boleh renew Takaful & Roadtax
              </li>
            </ul>
            <div className="text-center mt-6">
              <button onClick={() => handleShowForm()} className="bg-purple-500 text-white px-6 py-2 mt-4 rounded-lg hover:bg-purple-600 transition-colors duration-300">
                Renew Dahulu, Bayar Kemudian
              </button>
            </div>
          </div>
          {/* KasiRenew Express */}
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h3 className="text-2xl font-semibold text-center text-purple-500">KasiRenew <span className='font-bold text-yellow-500 italic'>Express</span></h3>
            <h4 className='text-xl mb-4 text-center'>(Khidmat 24 Jam)</h4>
            <p className="text-center text-gray-600 mb-6">Nak cepat? Renew Insuran Kenderaan Segera</p>
            <ul className="space-y-3 text-gray-700 mb-4">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Dapat Sebut Harga Segera
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Tiada Caj Transaksi
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Support pembayaran melalui FPX (Online Banking),  Kad Kredit & Kad Debit
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span> Boleh renew Takaful. Roadtax boleh diperbaharui sendiri.
              </li>
            </ul>
            <div className='bg-gray-100 p-4'>
              <h4 className='text-xl'>Turut menawarkan:</h4>
              <ul className="space-y-3 text-gray-700 py-2">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Umrah/Haji Travel Personal Accident (PA)
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Travel PA Takaful (Dalam/Luar Negara)
                </li>
              </ul>
            </div>
            <div className="text-center mt-6">
              <button 
                onClick={() => window.open('https://w1.financial-link.com.my/PremiumLink2/STMBPL/intro.jsp?qecWWCcwtatyz4g0CoCPOpp0y6QD8sKaRADQ69qmoes=', '_blank')}
                className="bg-purple-500 text-white px-6 py-2 mt-4 rounded-lg hover:bg-purple-600 transition-colors duration-300">
                  Dapatkan Sebut Harga Segera
              </button>
            </div>
          </div>
        </div>
      </div>)}
      {displayForm && (
        <div className={`flex justify-center transition-all duration-500 ease-in-out transform ${
          showForm ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
          }`} >
        {/* <div className="md:w-3/5 text-white mb-2">
          <h1 className="text-6xl md:text-6xl font-bold mb-4">Renew Insuran <span className='text-yellow-200'>Harga Telus</span></h1>
          <p className="text-lg md:text-xl mb-8">Tanpa markup yg melampau</p>
          <div className="flex mt-4 flex-wrap">
            <img src={paymentMethod1} alt="Payment Method 1" className="m-2 h-8" />
            <img src={paymentMethod3} alt="Payment Method 3" className="m-2 h-8" />
            <img src={paymentMethod4} alt="Payment Method 4" className="m-2 h-8" />
            <img src={paymentMethod5} alt="Payment Method 5" className="m-2 h-8" />
          </div>
        </div> */}
        <div className="md:w-2/3">
          <form onSubmit={handleFormSubmit} className="bg-white shadow-md rounded-lg p-6">
            <div className="mb-4">
              <div className="flex gap-4">
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    id="car"
                    name="vehicle_type"
                    value="car"
                    checked={selectedVehicle === 'car'}
                    onChange={handleVehicleChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="car"
                    className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                      selectedVehicle === 'car'
                        ? 'bg-gray-500 text-white'
                        : 'bg-white border border-gray-500 text-gray-500'
                    }`}
                  >
                    <RiCarLine className="w-5 h-5 mr-2" /> Car
                  </label>
                </div>
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    id="motorcycle"
                    name="vehicle_type"
                    value="motorcycle"
                    checked={selectedVehicle === 'motorcycle'}
                    onChange={handleVehicleChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="motorcycle"
                    className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                      selectedVehicle === 'motorcycle'
                        ? 'bg-gray-500 text-white'
                        : 'bg-white border border-gray-500 text-gray-500'
                    }`}
                  >
                    <RiMotorbikeLine className="w-5 h-5 mr-2" /> Motorcycle
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="nama" className="block text-gray-800 font-semibold mb-2">Nama</label>
              <input
                type="text"
                id="nama"
                name="nama"
                placeholder="Masukkan nama disini"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.nama}
              />
            </div>
            <div className="mb-4 flex gap-4">
              <div className="w-1/4">
                <label htmlFor="id_type" className="block text-gray-800 font-semibold mb-2">ID Type</label>
                <select
                  id="id_type"
                  name="id_type"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                  onChange={handleInputChange}
                  value={formData.id_type}
                >
                  <option value="1">NRIC</option>
                  <option value="2">Passport</option>
                  <option value="3">Army/Police</option>
                </select>
              </div>
              <div className="w-3/4">
                <label htmlFor="id_number" className="block text-gray-800 font-semibold mb-2">ID Number</label>
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  placeholder="XXXXXX-XX-XXXX"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                  required
                  onChange={handleInputChange}
                  value={formData.id_number}
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="plate_no" className="block text-gray-800 font-semibold mb-2">Plate No</label>
              <input
                type="text"
                id="plate_no"
                name="plate_no"
                placeholder="ABC1234"
                className="w-full p-3 rounded-md border border-gray-300 uppercase focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.plate_no}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-800 font-semibold mb-2">Emel</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Masukkan emel disini"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.email}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="whatsapp_no" className="block text-gray-800 font-semibold mb-2">No WhatsApp</label>
              <input
                type="text"
                id="whatsapp_no"
                name="whatsapp_no"
                placeholder="0123456789"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.whatsapp_no}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="flex space-x-2 md:space-x-4 w-full bg-purple-500 text-white p-3 rounded-md font-semibold hover:bg-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
              >
                <FaWhatsapp className="text-white text-2xl" />
                <p>WhatsApp untuk Mohon Quotation Percuma</p>
              </button>
            </div>
          </form>
        </div>
      </div>)}
    </section>
  );
}

export default Hero;
