import React from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";

function FeaturesTM() {
  return (
    <div>
      <ul className="text-md mb-8">
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> Takaful Kemalangan untuk Pemandu dan
            Penumpang
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> 24 jam Khidmat Tunda sehingga jarak 50km
            bagi setiap perjalanan. Tiada had penggunaan dalam 1 tahun.
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> 24-jam Bantuan Tepi Jalan
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> Khidmat Penukaran atau Jumpstart Bateri
            (*Kos bateri baru ditanggung oleh pemilik kenderaan.)
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> Khidmat Penambahan Minyak Tangki Kosong
            (*Kos petrol ditanggung oleh pemilik kenderaan.)
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> Khidmat Penukaran Tayar Pancit (*Kos tayar
            baru ditanggung oleh pemilik kenderaan.)
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>6 Bulan Waranti</strong> untuk kerosakan yg diperbaiki oleh
            bengkel panel kami
          </span>
        </li>
      </ul>
    </div>
  );
}

export default FeaturesTM;
