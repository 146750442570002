import React, { useState } from 'react';
import fpxList from '../../img/fpx-list.png';
import grabpaylater from '../../img/grabpay-logo.jpg';

function FPXPayment({ totalPrice, client_id, name, email, mobile, amount }) {
  const [loading, setLoading] = useState(false);
  const handleCheckout = () => {
    setLoading(true);
    const redirectUrl = `https://pay.kasirenew.com/fpx/billplzpost.php?clientId=${client_id}&name=${name}&email=${email}&mobile=${mobile}&amount=${amount}&reference_1_label=&reference_2_label=`;
  
    // Redirect to the constructed URL
    window.location.href = redirectUrl;
  };

  const cajServis = '1.10';

  const subtotal = totalPrice;
  const services = cajServis;
  const total = (parseFloat(subtotal) + parseFloat(cajServis)).toFixed(2);

  // const subtotal2 = subtotal.toFixed(2);
  // const total2 = total.toFixed(2);
  localStorage.setItem('subtotal', subtotal);
  localStorage.setItem('total', total);
  localStorage.setItem('services', services);
  localStorage.setItem('email', email);
  
  return (
    <div>
      <img src={fpxList} alt="FPX" className="h-16 mx-auto mb-4" />

      <p className='text-center'>Untuk pembayaran melalui <span className='font-bold'>FPX</span>, klik butang dibawah untuk membuat pembayaran.</p>
      <p className='text-center mt-6'>*Caj perkhidmatan sebanyak <span className='font-bold p-2 rounded-full text-white bg-red-500'>RM1.10</span> akan dikenakan jika anda memilih kaedah pembayaran ini.</p>
      
      <div className="text-center mt-6">
        <button
          onClick={handleCheckout}
          className="flex items-center mx-auto bg-purple-500 text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
        >
          <p className='mr-2'>Proceed to Payment</p>
          {loading && (
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-200"></div>
          )}
        </button>
      </div>
    </div>
  );
}

export default FPXPayment;
