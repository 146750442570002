import React from 'react';
import axios from 'axios';

const SendEmail = ({ to, subject, text }) => {

  const sendEmail = async () => {
    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/smtp/email',
        {
          sender: { name: 'KasiRenew', email: 'noreply@kasirenew.com' },
          to: [{ email: to }],
          subject: subject,
          htmlContent: text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': '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',
          },
        }
      );

      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending email:', error.response.data);
    }
  };

  sendEmail();

  return (
    <div>
      
    </div>
  );
};

export default SendEmail;
