import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'; // Your home page component
import ThankYou from './pages/ThankYou'; // Import your thank you page component
import Pricing from './pages/Pricing';
import Checkout from './pages/Checkout2';
import Success from './pages/Success';
import Failed from './pages/Failed';
import Tnc from './pages/Tnc';
import RefundPolicy from './pages/RefundPolicy';
import Contact from './pages/Contact';
import SendReceipt from './pages/SendReceipt';
import Confirmation from './pages/Confirmation';
import PricingNew from './pages/PricingNew';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/sendreceipt" element={<SendReceipt />} />
          <Route path="/payment/confirmation/:payment_id/:paid_at" element={<Confirmation />} />
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<Tnc />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact-us" element={<Contact />} />
          {/* <Route path="/quotation/:token" element={<Pricing />} /> */}
          <Route path="/quotation/:token" element={<PricingNew />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment/success" element={<Success />} />
          <Route path="/payment/failed" element={<Failed />} />
      </Routes>
    </Router>
  );
}

export default App;
