import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Header from '../components/Header';
import Hero from '../components/Hero'; 
import Features from '../components/Features';
import HowToBuy from '../components/HowToBuy';
import CTA from '../components/CTA';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import FloatingActionButton from '../components/FloatingActionButton';
import Features2 from '../components/Features2';

function Home() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    
    if (refParam) {
      // Set the 'ref' cookie with an expiration date of 7 days
      Cookies.set('ref', refParam, { expires: 7 });
    }
  }, []);

  return (
    <div>
      <Header />
      <Hero />
      <Features2 />
      <HowToBuy />
      <CTA />
      {/* <Testimonial /> */}
      <Footer />
      <FloatingActionButton />
      {/* Other content of your website */}
    </div>
  );
}

export default Home;
