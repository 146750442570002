import React, { useState, useEffect } from "react";
import { FaCheck, FaCheckCircle, FaSpinner } from "react-icons/fa";

const AddOns = ({
  setTotalAddOn,
  roadtax,
  digitalRoadtax,
  fizikalRoadtax,
  windscreenCover,
  minWindscreenCover,
  windscreenPrice,
  specialPerils,
  towing,
  motorpaplus,
  legalliability,
  addRoadtax,
  addDigitalRoadtax,
  addFizikalRoadtax,
  setAddRoadtax,
  setAddDigitalRoadtax,
  setAddFizikalRoadtax,
  addWindscreen,
  setAddWindscreen,
  setWindscreenCover,
  addSpecialPerils,
  setAddSpecialPerils,
  addTowing,
  setAddTowing,
}) => {
  const [selectedOption, setSelectedOption] = useState("digital");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [windscreenCoverNew, setWindscreenCoverNew] = useState(windscreenCover);

  console.log("Windscreen: ", windscreenCoverNew);

  useEffect(() => {
    if (windscreenCover !== 0) {
      const intervalId = setInterval(() => {
        setWindscreenCover((prevValue) => {
          if (prevValue === '' || prevValue === undefined || prevValue === null) {
            return windscreenCover;
          }
          clearInterval(intervalId); // Clear the interval if the value is set
          return prevValue;
        });
        setWindscreenCoverNew((prevValue) => {
          if (prevValue === '' || prevValue === undefined || prevValue === null) {
            return windscreenCover;
          }
          clearInterval(intervalId); // Clear the interval if the value is set
          return prevValue;
        });
      }, 100); // Check every 100 milliseconds

      return () => clearInterval(intervalId); // Cleanup on component unmount or windscreenCover change
    }
  }, [windscreenCover]);

  const handleChange = (e) => {
    const enteredValue = e.target.value;
    setWindscreenCoverNew(enteredValue);
    setMessage("");
    setLoading(true);
    setApprove(false);

    setTimeout(() => {
      if (enteredValue < minWindscreenCover) {
        setWindscreenCover(minWindscreenCover);
        setWindscreenCoverNew(minWindscreenCover);
        setMessage(`Windscreen Cover perlu lebih dari RM${minWindscreenCover}`);
      } else {
        setWindscreenCover(enteredValue);
        setApprove(true);
      }
      setLoading(false);
    }, 3000);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "digital") {
      // handleToggle("digitalRoadtax");
      setAddDigitalRoadtax(!addDigitalRoadtax);
      setAddFizikalRoadtax(!addFizikalRoadtax);
    } else {
      // handleToggle("fizikalRoadtax");
      setAddDigitalRoadtax(!addDigitalRoadtax);
      setAddFizikalRoadtax(!addFizikalRoadtax);
    }
  };

  const handleToggle = (addOnType) => {
    if (addOnType === "roadtax") {
      setAddRoadtax(!addRoadtax);
      setAddDigitalRoadtax(!addDigitalRoadtax);
    } else if (addOnType === "digitalRoadtax") {
      setAddDigitalRoadtax(!addDigitalRoadtax);
    } else if (addOnType === "fizikalRoadtax") {
      setAddFizikalRoadtax(!addFizikalRoadtax);
    } else if (addOnType === "windscreen") {
      setAddWindscreen(!addWindscreen);
    } else if (addOnType === "specialPerils") {
      setAddSpecialPerils(!addSpecialPerils);
    } else if (addOnType === "towing") {
      setAddTowing(!addTowing);
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0.0;
    if (addRoadtax) {
      totalPrice += parseFloat(roadtax);
    }
    if (addDigitalRoadtax) {
      totalPrice += parseFloat(digitalRoadtax);
    }
    if (addFizikalRoadtax) {
      totalPrice += parseFloat(fizikalRoadtax);
    }
    if (addWindscreen) {
      totalPrice += parseFloat(windscreenPrice);
    }
    if (addSpecialPerils) {
      totalPrice += parseFloat(specialPerils);
    }
    if (addTowing) {
      totalPrice += parseFloat(towing);
    }
    return totalPrice.toFixed(2);
  };

  const addOnsList = () => {
    let addons = [];
    if (addRoadtax) {
      addons.push({ name: "Roadtax", price: roadtax });
    }
    if (addDigitalRoadtax) {
      addons.push({ name: "Digital Roadtax", price: digitalRoadtax });
    }
    if (addFizikalRoadtax) {
      addons.push({ name: "Fizikal Roadtax", price: fizikalRoadtax });
    }
    if (addWindscreen) {
      addons.push({ name: "Windscreen", price: windscreenPrice });
    }
    if (addSpecialPerils) {
      addons.push({ name: "Special Perils", price: specialPerils });
    }
    if (addTowing) {
      addons.push({ name: "Towing", price: towing });
    }
    return addons;
  };

  const totalPrice = calculateTotalPrice();
  const addons = JSON.stringify(addOnsList());

  setTotalAddOn(totalPrice);
  // localStorage.setItem("total_addons", totalPrice);
  localStorage.setItem("addons", addons);

  return (
    <div className="space-y-4">
      {roadtax > 0 && (
        <div>
          <div
            className="flex justify-between text-gray-700"
            onClick={() => handleToggle("roadtax")}
          >
            <label htmlFor="Roadtax" className="flex items-center">
              <input
                type="checkbox"
                id="roadtax"
                checked={addRoadtax}
                className="form-checkbox rounded-md text-indigo-600"
              />
              <span className="ml-3">Roadtax</span>
            </label>
            <span className="font-semibold">RM{roadtax}</span>
          </div>

          {addRoadtax && (
            <div className="flex flex-col">
              <p className="font-semibold text-black">Caj Servis:</p>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-blue-500"
                  value="digital"
                  checked={selectedOption === "digital"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 text-gray-700">
                  Roadtax Digital (+RM5.00)
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-blue-500"
                  value="physical"
                  checked={selectedOption === "physical"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 text-gray-700">
                  Penghantaran/Pos ke Pelanggan (+RM23.00)
                </span>
              </label>
            </div>
          )}
        </div>
      )}

      {windscreenCover > 0 && (
        <div>
          <div
            // onClick={() => handleToggle("windscreen")}
            className="flex justify-between text-gray-700"
          >
            <label htmlFor="Windscreen" className="flex items-center">
              <input
                type="checkbox"
                id="windscreen"
                checked
                className="form-checkbox rounded-md text-indigo-600"
              />
              <div className="flex items-center justify-between text-gray-700">
                <span className="ml-3 font-semibold">Windscreen Cover (RM):</span>
                <div className="ml-3">
                  <span>{windscreenCoverNew}</span>
                </div>
              </div>
            </label>
            {/* <span className="font-semibold">RM{windscreenPrice}</span> */}
          </div>
          {/* {addWindscreen && (
            <div>
              <div className="flex items-center justify-between text-gray-700">
                <span className="ml-4 w-2/3">Windscreen Cover (RM):</span>
                <div className="relative w-1/3">
                  <input
                    type="text"
                    value={windscreenCoverNew}
                    onChange={handleChange}
                    className="w-full p-2 pl-10 bg-white rounded-lg shadow-sm ring-1 ring-inset ring-gray-300"
                  />
                  {loading && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <FaSpinner className="animate-spin h-5 w-5 text-gray-400" />
                    </div>
                  )}
                  {approve && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <FaCheckCircle className="h-5 w-5 text-green-400" />
                    </div>
                  )}
                </div>
              </div>
              <p className="text-red-500 ml-4 mt-2">{message}</p>
            </div>
          )} */}
        </div>
      )}

      {specialPerils > 0 && (
        <div
          // onClick={() => handleToggle("specialPerils")}
          className="flex justify-between text-gray-700"
        >
          <label htmlFor="Special Perils" className="flex items-center">
            <input
              type="checkbox"
              id="specialPerils"
              checked
              className="form-checkbox rounded-md text-indigo-600"
            />
            <div className="ml-3 flex flex-col">
              <span className="font-semibold">Special Perils</span>
              <span className="text-sm">
                100% Perlindungan kerosakkan disebabkan bencana alam seperti
                banjir, taufan, ribut dan lain-lain.
              </span>
            </div>
          </label>
          {/* <span className="font-semibold">RM{specialPerils}</span> */}
        </div>
      )}

      {towing > 0 && (
        <div
          // onClick={() => handleToggle("towing")}
          className="flex justify-between text-gray-700"
        >
          <label htmlFor="Towing" className="flex items-center">
            <input
              type="checkbox"
              id="towing"
              checked
              className="form-checkbox rounded-md text-indigo-600"
            />
            <div className="ml-3 flex flex-col">
              <span className="font-semibold">Towing</span>
            </div>
          </label>
        </div>
      )}

      {motorpaplus > 0 && (
        <div
          // onClick={() => handleToggle("towing")}
          className="flex justify-between text-gray-700"
        >
          <label htmlFor="MotorPaPlus" className="flex items-center">
            <input
              type="checkbox"
              id="motorpaplus"
              checked
              className="form-checkbox rounded-md text-indigo-600"
            />
            <div className="ml-3 flex flex-col">
              <span className="font-semibold">Motor Pa Plus</span>
            </div>
          </label>
        </div>
      )}

      {legalliability > 0 && (
        <div
          // onClick={() => handleToggle("towing")}
          className="flex justify-between text-gray-700"
        >
          <label htmlFor="Legalliability" className="flex items-center">
            <input
              type="checkbox"
              id="legalliability"
              checked
              className="form-checkbox rounded-md text-indigo-600"
            />
            <div className="ml-3 flex flex-col">
              <span className="font-semibold">Legalliability</span>
            </div>
          </label>
        </div>
      )}

      {/* <div className="flex justify-between text-gray-700">
        <span>Total Add-on:</span>
        <span className="font-semibold">RM{totalPrice}</span>
      </div> */}
    </div>
  );
};

export default AddOns;
