import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from React Router

const FailedMessage = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center px-8 container mx-auto">
      <div className="text-6xl text-red-500">
        <FaTimesCircle size={100} />
      </div>
      <div className="text-2xl text-red-500 mt-4">Gagal</div>
      <div className="text-lg text-center text-gray-600 mt-2">
        Maaf. Pembayaran anda tidak berjaya. Boleh hubungi kami dengan klik butang WhatsApp dibawah jika terdapat apa-apa masalah. Atau boleh cuba sekali lagi.
      </div>
      <Link to="/checkout"> {/* Link to the /checkout route */}
        <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 mt-4 rounded">
          Cuba sekali lagi
        </button>
      </Link>
    </div>
  );
};

export default FailedMessage;
