import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SendReceipt = ({ payment_id , paid_at }) => {
  const navigate = useNavigate();
  const to = localStorage.getItem('email');
  const plate_no = localStorage.getItem('plate_no');
  const status = 'Paid';
  const type = 'Billplz';
  const subtotal = localStorage.getItem('subtotal');
  const services = localStorage.getItem('services');
  const total = localStorage.getItem('total');

  const [submitEmail, setSubmitEmail] = useState(true);

  useEffect(() => {
    const sendEmail = async () => {
      try {
        const response = await axios.post(
          'https://api.brevo.com/v3/smtp/email',
          {
            sender: { name: 'KasiRenew', email: 'noreply@kasirenew.com' },
            to: [{ email: to }, { email:'kasirenew@gmail.com' }],
            templateId: 5,
            params: {
              ref: payment_id,
              datetime: paid_at,
              plate_no: plate_no,
              status: status,
              type: type,
              subtotal: subtotal,
              services: services,
              total: total,
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-key': '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',
            },
          }
        );
  
        console.log('Email sent successfully:', response.data);
        
      } catch (error) {
        console.error('Error sending email:', error.response.data);
      } finally {
        navigate('/payment/success')
      }
    };
  
    sendEmail();
  }, []);

  return (
    <div>
      
    </div>
  );
};

export default SendReceipt;
