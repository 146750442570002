import React from 'react';
import Header from '../components/Header';
import { RiFacebookFill, RiTwitterFill, RiInstagramFill, RiTiktokFill } from 'react-icons/ri';

function ThankYou() {
  return (
    <div>
        <Header />
        <section className="bg-gray-100 min-h-screen flex flex-col justify-center py-16 px-8 md:px-16 lg:px-24 xl:px-32">
        <div className="container mx-auto text-center">
            <h1 className="text-3xl md:text-4xl font-bold mb-8">Terima kasih kerana memohon quotation dari kami</h1>
            <p className="text-lg text-gray-600">
            Team kami akan hubungi anda di WhatsApp secepat mungkin. Tunggu ya... :)
            </p>
            <p className="text-lg text-gray-600 mb-8">
            Sementara itu, layarilah akaun media sosial kami untuk dapat info berkenaan takaful & insuran.
            </p>
            <div className="flex justify-center space-x-4">
                <a
                    href="#"
                    className="text-purple-600 hover:text-purple-500 text-2xl"
                >
                    <RiFacebookFill />
                </a>
                <a
                    href="#"
                    className="text-purple-600 hover:text-purple-500 text-2xl"
                >
                    <RiTwitterFill />
                </a>
                <a
                    href="#"
                    className="text-purple-600 hover:text-purple-500 text-2xl"
                >
                    <RiInstagramFill />
                </a>
                <a
                    href="#"
                    className="text-purple-600 hover:text-purple-500 text-2xl"
                >
                    <RiTiktokFill />
                </a>
            </div>
        </div>
        </section>
    </div>
  );
}

export default ThankYou;
