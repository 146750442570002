import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SuccessMessage from '../components/messages/SuccessMessage';
import FloatingActionButton from '../components/FloatingActionButton';

function Success() {

  return (
    <div>
      <Header />
      <SuccessMessage />
      <Footer />
      <FloatingActionButton />
    </div>
  );
}

export default Success;
