import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function OnlineTransfer({ totalPrice }) {
  const sendLink = (whatsapp_no) => {
    // const message = `Terima%20kasih%20kerana%20renew%20insuran%20kenderaan%20di%20KasiRenew.com.%20Disini%20saya%20attach%20cover-note%20insuran%20kenderaan%20Tn%2FPn.%0D%0A%0D%0ATn%2FPn%20nak%20renew%20Roadtax%20sekali%3F`;
    const whatsappLink = `https://wa.me/601120888930`;
 
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div>
      <p className='text-center'>Anda boleh melakukan Online Transfer ke akaun bank berikut:</p>

      <div className='my-6'>
        <p className='text-center text-xl font-bold'>Maybank</p>
        <p className='text-center text-xl font-bold'>5648 5630 5849</p>
        <p className='text-center text-xl font-bold'>Mutaqaddim Enterprise</p>
      </div>

      <p className='text-center'>Setelah selesai membuat pembayaran, hantar bukti pembayaran ke WhatsApp kami dengan klik butang dibawah:</p>
      
      <div className="text-center mt-6">
        <button
          onClick={() => sendLink()}
          className="bg-green-500 text-white p-3 rounded-full flex items-center mx-auto"
        >
          <FaWhatsapp className="text-2xl mr-4" /> WhatsApp Kami
        </button>
      </div>
    </div>
  );
}

export default OnlineTransfer;
