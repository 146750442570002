import React from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";

function FeaturesTIMotor() {
  return (
    <div>
      <ul className="text-md mb-8">
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
          <strong>PERCUMA</strong> Perlindungan dari kehilangan disebabkan kecurian, kerosakkan disebabkan kemalangan dan kebakaran
          </span>
        </li>
        <li className="flex items-center mb-2">
          <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
          <span className="flex-1">
            <strong>PERCUMA</strong> 24-jam Bantuan Tepi Jalan
          </span>
        </li>
      </ul>
    </div>
  );
}

export default FeaturesTIMotor;
