import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SendReceipt from './SendReceipt';

const Confirmation = React.memo(() => {
  const { payment_id, paid_at } = useParams();

    SendReceipt({ payment_id, paid_at });

  return (
    <div>
      {/* Your component logic here */}
    </div>
  );
});

export default Confirmation;
