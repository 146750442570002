import React from 'react';

function HowToBuy() {
  const steps = [
    {
      title: '1: Mohon Quotation',
      description: 'Lengkapkan borang permohonan quotation di atas. Kemudian, klik butang "Mohon Quotation Percuma".',
      image: require('../img/step1.jpg'),
    },
    {
      title: '2: Terima Mesej Dari Kami',
      description: 'Terima mesej dari kami di WhatsApp. Jika pertama kali anda renew dengan kami, kami memerlukan sedikit maklumat tambahan berkenaan kenderaan anda. Balas mesej kami dengan maklumat yang diminta.',
      image: require('../img/step2.jpg'),
    },
    {
      title: '3: Terima Quotation',
      description: 'Kemudian, kami akan beri quotation kepada anda. Klik pada link yang diberi.',
      image: require('../img/step3.jpg'),
    },
    {
      title: '4: Buat Pembayaran',
      description: 'Jika setuju, boleh terus membuat pembayaran.',
      image: require('../img/step4.jpg'),
    },
    {
      title: '5: Terima Cover Note & Roadtax',
      description: 'Sesudah membuat pembayaran, kami akan secepat mungkin menyiapkan cover note dan renewkan roadtax untuk anda. *Roadtax digital di atas permintaan anda.',
      image: require('../img/step5.jpg'),
    },
    {
      title: '6: Cek di myJPJ tentang status roadtax anda',
      description: 'Dah siap semua, cek di aplikasi myJPJ tentang status roadtax anda (jika anda pilih untuk renew roadtax dengan kami)',
      image: require('../img/step6.jpg'),
    },
  ];

  return (
    <section className="bg-gray-200 py-16 px-8 md:px-16 lg:px-24 xl:px-32">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Cara Renew di KasiRenew.com</h2>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="col-span-1 md:col-span-2">
              <div className="mb-4">
                <img
                  src={step.image}
                  alt={step.title}
                  className="w-64 h-auto mx-auto rounded-lg"
                />
              </div>
              <div className="text-center">
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HowToBuy;
