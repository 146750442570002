import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="py-24 px-8">
        <div className="mt-8 mx-auto bg-white py-8 px-8 md:px-16 rounded shadow">
          <h1 className="text-3xl mb-6 text-center">Hubungi Kami</h1>
          <p className="text-gray-700 text-center">
            Jika ada apa-apa pertanyaan berkenaan polisi pemulangan wang, boleh hubungi kami menerusi WhatsApp dengan klik butang berikut:
          </p>
          <div className="flex justify-center mt-8">
            <a
              href="https://wa.me/601120888930"
              target="_blank"
              rel="noopener noreferrer"
              className="px-5 py-3 rounded-lg bg-purple-600 hover:bg-purple-700 text-white"
            >
              WhatsApp Kami
            </a>
          </div>
          <div className="mt-8 text-center">
            <p className='mb-5 text-gray-700'>Atau boleh hubungi kami di talian berikut:</p>
            <p className="text-gray-700">
              <strong>No Telefon:</strong> 01120888930
            </p>
            <p className="text-gray-700">
              <strong>Email:</strong> <a href="mailto:kasirenew@gmail.com">kasirenew@gmail.com</a>
            </p>
            <p className="text-gray-700">
              <strong>Alamat:</strong> DC1-07, JALAN PANGSA BAIDURI, TAMAN DESA BAIDURI<br />
              CHERAS<br />
              56000 KUALA LUMPUR<br />
              WILAYAH PERSEKUTUAN
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
