import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ClientInfo from '../components/ClientInfo';
import PricingTable2 from '../components/PricingTable2';
import Loading from '../components/Loading';

const PricingNew = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [client_id, setClientId] = useState('');
  const [status, setStatus] = useState('');
  const [id_number, setIdNumber] = useState('');
  const [plate_no, setPlateNo] = useState('');
  const [roadtax, setRoadtax] = useState('');
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [vehicle_type, setVehicleType] = useState('');
  const [takaful_options, setTakafulOptions] = useState('');
  const [party_options, setPartyOptions] = useState('');
  const [price, setPrice] = useState('');
  const [windscreen, setWindscreen] = useState('');
  const [min_windscreen, setMinWindscreen] = useState('');
  const [windscreen_price, setWindscreenPrice] = useState('');
  const [agreed_value, setAgreedValue] = useState('');
  const [special_perils, setSpecialPerils] = useState('');
  const [towing, setTowing] = useState('');
  const [motorpaplus, setMotorPaPlus] = useState('');
  const [legalliability, setLegalliability] = useState('');
  const [cart, setCart] = useState('');
  const [market_value, setMarketValue] = useState('');
  const [sum_cover, setSumCover] = useState('');
  const [ncd, setNcd] = useState('');
  const [serviceTax, setServiceTax] = useState('');
  const [stampDuty, setStampDuty] = useState('');

  useEffect(() => {
    // Make an API request to fetch data based on the token
    axios.get(`https://api.kasirenew.com/api/quotation/${token}`)
      .then((response) => {
        if (response.status === 404) {
          throw new Error('Quotation not found');
        }
        localStorage.setItem('client_id', response.data.client.id);
        setClientId(response.data.client.id);
        setIdNumber(response.data.client.id_number);
        setPlateNo(response.data.client.plate_no);
        setStatus(response.data.client.status);
        setRoadtax(response.data.quotation.roadtax);
        setFromDate(response.data.quotation.from_date);
        setToDate(response.data.quotation.to_date);
        setVehicleType(response.data.client.vehicle_type);
        setTakafulOptions(response.data.quotation.takaful_options);
        setPartyOptions(response.data.quotation.party_options);
        setPrice(response.data.quotation.price);
        setWindscreen(response.data.quotation.windscreen);
        setMinWindscreen(response.data.quotation.min_windscreen);
        setWindscreenPrice(response.data.quotation.windscreen_price);
        setAgreedValue(response.data.quotation.agreed_value);
        setSpecialPerils(response.data.quotation.special_perils);
        setTowing(response.data.quotation.towing);
        setMotorPaPlus(response.data.quotation.motorpaplus);
        setLegalliability(response.data.quotation.legalliability);
        setCart(response.data.quotation.cart);
        setMarketValue(response.data.quotation.market_value);
        setSumCover(response.data.quotation.sum_cover);
        setNcd(response.data.quotation.ncd);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  useEffect(() => {
    // Make an API request to fetch data based on the token
    axios.get(`https://api.kasirenew.com/api/settings`)
      .then((response) => {
        if (response.status === 404) {
          throw new Error('Quotation not found');
        }
        setServiceTax(response.data.services_tax);
        setStampDuty(response.data.service_charges);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  // const updatedPrice = price - (price * ncd/100);
  // const priceAfterTax = updatedPrice + updatedPrice*(serviceTax/100) + parseFloat(stampDuty);
  // const priceAfterTax = parseFloat(updatedPrice) + parseFloat(updatedPrice)*(parseFloat(serviceTax)/100) + parseFloat(stampDuty);

  return (
    <div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ):(
        <div>
          <Header />
          <div className="bg-gray-100 min-h-screen mt-12 py-16 px-4 md:px-8 lg:px-12 xl:px-16">
            <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">
              Sebut Harga
            </h1>
            <ClientInfo id_number={id_number} plate_no={plate_no} from_date={from_date} to_date={to_date} />
            <PricingTable2 client_id={client_id} status={status} roadtax={roadtax} price={price} windscreen={windscreen} min_windscreen={min_windscreen} windscreen_price={windscreen_price} agreed_value={agreed_value} special_perils={special_perils} towing={towing} motorpaplus={motorpaplus} legalliability={legalliability} cart={cart} ncd={ncd} market_value={market_value} sum_cover={sum_cover} vehicle_type={vehicle_type} takaful_options={takaful_options} party_options={party_options} setLoading={setLoading} />
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default PricingNew;
