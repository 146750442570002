import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function FloatingActionButton() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=601120888930"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-600 hover:bg-green-700 focus:bg-green-700 transition duration-300 ease-in-out rounded-full w-12 h-12 flex items-center justify-center shadow-md"
    >
      <FaWhatsapp className="text-white text-xl" />
    </a>
  );
}

export default FloatingActionButton;
