import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FeaturesTI from "./FeaturesTI";
import FeaturesTM from "./FeaturesTM";
import FeaturesThirdParty from "./FeaturesThirdParty";
import takafulmalaysia from "../img/takafulmalaysia.png";
import takafulikhlas from "../img/takafulikhlas.png";
import AddOns from "./AddOns";
import FeaturesTMMotor from "./FeaturesTMMotor";
import FeaturesTIMotor from "./FeaturesTIMotor";

const PricingTable2 = ({
  client_id,
  status,
  roadtax, 
  price,
  windscreen,
  min_windscreen,
  // windscreen_price,
  special_perils,
  towing,
  motorpaplus,
  legalliability,
  ncd,
  market_value,
  sum_cover,
  vehicle_type,
  takaful_options,
  party_options,
  setLoading,
}) => {
  const navigate = useNavigate();
  const digitalRoadtax = 5.0;
  const fizikalRoadtax = 23.0;

  const [addRoadtax, setAddRoadtax] = useState(false);
  const [addDigitalRoadtax, setAddDigitalRoadtax] = useState(false);
  const [addFizikalRoadtax, setAddFizikalRoadtax] = useState(false);
  const [addWindscreen, setAddWindscreen] = useState(false);
  const [windscreenCover, setWindscreenCover] = useState(windscreen);
  const [minWindscreenCover, setMinWindscreenCover] = useState(min_windscreen);
  const [addSpecialPerils, setAddSpecialPerils] = useState(false);
  const [addTowing, setAddTowing] = useState(false);
  const [addMotorPaPlus, setAddMotorPaPlus] = useState(false);
  const [addLegalliability, setAddLegalliability] = useState(false);
  const [totalAddOn, setTotalAddOn] = useState("0");

  const windscreenTax = parseFloat(windscreenCover) * 0.15 * 0.08;
  const windscreen_price = windscreenCover
    ? parseFloat(windscreenCover) * 0.15 + windscreenTax
    : 0;

  const specialPerilsTax = parseFloat(special_perils) * 0.08;
  const specialPerils = parseFloat(special_perils) + specialPerilsTax;

  const towingTax = parseFloat(towing) * 0.08;
  const towingPrice = parseFloat(towing) + towingTax;

  const takafulImgSrc =
    takaful_options === "Takaful Malaysia" ? takafulmalaysia : takafulikhlas;

  const totalPrice = parseFloat(price) + parseFloat(totalAddOn);

  const spaylaterBasic =
    (parseFloat(totalPrice) + parseFloat(totalPrice) * 0.09) / 6;
  const grabBasic = parseFloat(totalPrice) / 0.935 / 4;

  const handleRenew = () => {
    setLoading(true);

    if (status === "paid" || status === "completed") {
      window.location.href = "https://kasirenew.com/payment/success";
    } else {
      const payload = {
        client_id: client_id,
        price: price,
        roadtax: addRoadtax ? roadtax : 0,
        roadtax_digital: addDigitalRoadtax ? digitalRoadtax : 0,
        roadtax_fizikal: addFizikalRoadtax ? fizikalRoadtax : 0,
        windscreen: addWindscreen ? windscreen_price : 0,
        windscreen_cover: addWindscreen ? windscreenCover : 0,
        special_perils: addSpecialPerils ? specialPerils : 0,
        towing: addTowing ? towingPrice : 0,
      };

      // Check if data with 'client_id' exists in 'cart'
      axios
        .get(`https://api.kasirenew.com/api/cartclient/${client_id}`)
        .then((response) => {
          const cartItem = response.data.client_id;

          if (cartItem) {
            // If data exists, update it
            axios
              .put(
                `https://api.kasirenew.com/api/updatecart/${client_id}`,
                payload
              )
              .then((response) => {
                console.log("Data updated:", response.data);
                // Navigate to the checkout page
                navigate("/checkout");
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          } else {
            // If data doesn't exist, add it
            axios
              .post("https://api.kasirenew.com/api/cart", payload)
              .then((response) => {
                console.log("Data added:", response.data);
                // Navigate to the checkout page
                navigate("/checkout");
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking data:", error);
        });
    }
  };

  return (
    <div className="flex justify-center mt-8">
      <div className="mx-2 w-full md:max-w-lg">
        {/* Pricing Option 2: Premium */}
        <div className="bg-purple-500 shadow-lg rounded-lg py-10 px-4 md:p-10 text-white">
          <div className="text-center">
            <h2 className="text-2xl font-semibold">
              KasiRenew Protect <span className="text-yellow-300">Plus</span>
            </h2>
            <p className="text-gray-200 mb-8">{party_options}</p>
          </div>
          <div className="bg-white p-5 my-6 rounded-lg">
            <img
              className="h-14 mx-auto"
              src={takafulImgSrc}
              alt={takaful_options}
            />
          </div>
          <div className="text-center">
            <span className="text-5xl font-bold">
              RM{totalPrice.toFixed(2)}
            </span>
          </div>

          <div className="text-center mb-8">
            <p>
              NCD: <strong>{ncd}%</strong>
            </p>
            <p>
              Market Value: <strong>RM{market_value}</strong>
            </p>
            <p>
              Sum Cover: <strong>RM{sum_cover}</strong>
            </p>
          </div>

          <div className="bg-white p-4 mb-8 rounded-lg">
            <AddOns
              setTotalAddOn={setTotalAddOn}
              roadtax={roadtax}
              digitalRoadtax={digitalRoadtax}
              fizikalRoadtax={fizikalRoadtax}
              windscreenCover={windscreen}
              minWindscreenCover={min_windscreen}
              windscreenPrice={windscreen_price}
              specialPerils={specialPerils}
              towing={towingPrice}
              motorpaplus={motorpaplus}
              legalliability={legalliability}
              addRoadtax={addRoadtax}
              addDigitalRoadtax={addDigitalRoadtax}
              addFizikalRoadtax={addFizikalRoadtax}
              setAddRoadtax={setAddRoadtax}
              setAddDigitalRoadtax={setAddDigitalRoadtax}
              setAddFizikalRoadtax={setAddFizikalRoadtax}
              addWindscreen={addWindscreen}
              setAddWindscreen={setAddWindscreen}
              setWindscreenCover={setWindscreenCover}
              addSpecialPerils={addSpecialPerils}
              setAddSpecialPerils={setAddSpecialPerils}
              addTowing={addTowing}
              setAddTowing={setAddTowing}
            />
          </div>

          <div className="mb-8 text-center text-white">
            <div>
              SPayLater:{" "}
              <span className="font-bold">RM{spaylaterBasic.toFixed(2)}</span> x
              6 bulan
            </div>
            <div>
              Grab PayLater:{" "}
              <span className="font-bold">RM{grabBasic.toFixed(2)}</span> x 4
              bulan
            </div>
          </div>
          {vehicle_type === "car" ? (
            <div>
              {party_options === "Comprehensive" ? (
                <div>
                  {takaful_options === "Takaful Malaysia" ? (
                    <FeaturesTM />
                  ) : (
                    <FeaturesTI />
                  )}
                </div>
              ) : (
                <div>
                  <FeaturesThirdParty />
                </div>
              )}
            </div>
          ) : (
            <div>
              {party_options === "Comprehensive" ? (
                <div>
                  {takaful_options === "Takaful Malaysia" ? (
                    <FeaturesTMMotor />
                  ) : (
                    <FeaturesTIMotor />
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
          <button
            onClick={handleRenew}
            className="bg-white hover:bg-gray-200 text-purple-500 font-bold py-3 px-6 rounded-full w-full"
          >
            Renew Sekarang
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingTable2;
