import React from 'react';
import { RiMoneyDollarCircleLine, RiSmartphoneLine, RiServiceLine } from 'react-icons/ri';

function Features2() {

  return (
    <section className="bg-gray-100 py-16 px-8 md:px-16 lg:px-24 xl:px-32">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Kenapa Kena Renew di KasiRenew.com?</h2>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:space-x-4 space-y-12 md:space-y-0 py-12'>
        <div className='flex flex-col items-center justify-center text-center space-y-2 flex-1'>
          <RiServiceLine size={100} color="#8B5CF6" />
          <h2 className='text-xl font-bold'>Senang nak claim</h2>
          <p>Kemalangan? Kehilangan? Kebakaran? Kami akan bantu anda claim sampai dapat.</p>
          <span className="text-xs">
            *Mengikut terma dan syarat didalam policy
          </span>
        </div>
        <div className='flex flex-col items-center justify-center text-center space-y-2 flex-1'>
          <RiSmartphoneLine size={100} color="#8B5CF6" />
          <h2 className='text-xl font-bold'>Mudah Hubungi Kami</h2>
          <p>Nak call? Nak mesej? Nak WhatsApp? Kami akan pastikan pelanggan KasiRenew.com mudah untuk hubungi kami. Apa-apa hal, roger jer!</p>
        </div>
        <div className='flex flex-col items-center justify-center text-center space-y-2 flex-1'>
          <RiMoneyDollarCircleLine size={100} color="#8B5CF6" />
          <h2 className='text-xl font-bold'>Ansuran & Patuh Syariah</h2>
          <p>Kami sentiasa berusaha untuk menyediakan perkhidmatan pembayaran secara ansuran dan patuh syariah supaya memudahkan anda dan selamat didunia dan akhirat.</p>
        </div>
      </div>

    </section>
  );
}

export default Features2;
