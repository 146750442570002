import React from 'react';

function CTA() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="bg-purple-500 py-16 px-8 md:px-16 lg:px-24 xl:px-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl md:text-4xl text-white font-bold mb-4">Renew roadtax dan insuran anda sekarang</h2>
        <p className="text-lg text-white mb-8">1 tahun kami akan berkhidmat untuk anda sehingga next renew. Kemalangan? Kehilangan? Kebakaran? Kami akan bantu anda claim sampai dapat.</p>
        <button
          className="bg-white text-purple-500 py-3 px-6 rounded-full text-lg font-semibold hover:bg-purple-100 hover:text-purple-600 transition duration-300 ease-in-out"
          onClick={scrollToTop}
        >
          Mohon Quotation Percuma
        </button>
      </div>
    </section>
  );
}

export default CTA;
