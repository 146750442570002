import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Tnc() {
  return (
    <div>
      <Header />
      <div className="container mx-auto mt-8 py-20">
        <h1 className="text-3xl font-bold mb-4 text-center">Terms and Conditions</h1>
        <div className="text-gray-700">
          <section className="max-w-2xl mx-auto mb-8 p-5">
            {/* Intro */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
              <p className="text-gray-700">
                These Terms of Service ("Terms") govern your access to and use of the application, website, products, and services ("Services") provided by KasiRenew, so please read these Terms carefully, before using our Services.
              </p>
              <p className="text-gray-700">
                References to "you" in these Terms of Service means you, your duly authorized representatives and any entity you may represent in connection with your use of the Services. Any reference made in these Terms of Service to "KasiRenew" shall be deemed to have been made to Mutaqaddim Enterprise (002395478-U), its subsidiaries, successors, assignees, and affiliates, as well as any company that controls KasiRenew, directly or indirectly, and any other subsidiary of that controlling company, as applicable.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Eligibility and Agreement */}
            <div className="mb-6">
              <p className="text-gray-700">
                Not all of the products or services described on this Site are available in all areas of Malaysia, and you may not be eligible for them. We reserve the right to determine eligibility. By accessing or using our Services, you agree to be bound by these Terms and by our Privacy Policy. This Privacy Policy includes important information about the use of information in determining your premium. If you do not agree to these Terms or the Privacy Policy, please do not access or otherwise use our Services or any information contained herein.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Modification of Terms */}
            <div className="mb-6">
              <p className="text-gray-700">
                We reserve the right, at our discretion, to modify these Terms from time to time, which modification will be effective upon the posting of the modification to our application ("App") or website (collectively, "the Site"), and will apply to causes of action arising after the effective date of the change. You should continue to check the Site for changes. Your continued use of our Site or otherwise the Services following the posting of changes to these Terms will mean that you accept those changes.
              </p>
              <p className="text-gray-700">
                By accessing or using our Services, you confirm that:
              </p>
              <ul className="list-disc ml-6 mb-4 text-gray-700">
                <li>You can form a binding contract with us; and</li>
                <li>You are over the age of 18, as the Services are not intended for children under 18. If it comes to our attention through reliable means that a registered user is a child under 18 years of age, we will cancel that user's account and/or access to the Services.</li>
              </ul>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Fees and Third-Party Agreements */}
            <div className="mb-6">
              <p className="text-gray-700">
                You agree to pay all fees (if any) charged by Google Play or App Store in connection with the Services and the App. You agree to comply with, and your license to use the App is conditioned upon your compliance with, all applicable third-party terms of use and agreements (e.g., Google Play's or App Store's terms and policies) when using the Services and the App. You acknowledge that Google Play and/or App Store (and its subsidiaries) are third-party beneficiaries of the Terms and will have the right to enforce them.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Headings and Financial Decisions */}
            <div className="mb-6">
              <p className="text-gray-700">
                The headings and captions used in these Terms are inserted for convenience only and will not affect the meaning or interpretation of these Terms of Use.
              </p>
              <p className="text-gray-700">
                Do Not Rely on our Site for Your Financial Decisions. The contents on the Site and elsewhere are for convenience and information purposes only. Nothing therein should be construed as rendering tax, legal, financial, investment, insurance, or accounting advice. The availability of any information on the Site is not a recommendation or opinion for you to buy or sell any product or participate in any transaction.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* WARRANTIES AND DISCLAIMERS */}
            <div className='mb-6'>
              <h2 className="text-2xl font-bold mb-4">WARRANTIES AND DISCLAIMERS</h2>
              <p className="text-gray-700">
                We provide our Services using a commercially reasonable level of skill and care, and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services.
              </p>
              <p className="text-gray-700">
                While KasiRenew does not warrant the Services or its reliability, availability, or ability to meet your needs,
              </p>
              <p className="text-gray-700">
                KasiRenew will take reasonable measures to ensure the accuracy and validity of information on the Site.
              </p>
              <p className="text-gray-700">
                KasiRenew does not warrant or represent that access to the whole or part(s) of this website, the materials, information and/or functions contained therein will be provided uninterrupted.
              </p>
              {/* Add more paragraphs as needed */}
            </div>
            {/* WEB AND MOBILE APPLICATION */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">WEB AND MOBILE APPLICATION</h2>
              <p className="text-gray-700">
                The information available on the Site is not and should not be construed as insurance advice by KasiRenew. While KasiRenew will take reasonable measures to ensure the accuracy and validity of information on the Site, KasiRenew is not liable for any inaccurate, missing, or misconstrued information and makes no guarantee as to the quality and precision of such information or content. Any insurance purchasing conclusions and decisions such as coverage amounts, limits, and deductibles are completely and solely the responsibility of the insured based on information from the insurance agent on the Site. KasiRenew may update the Site from time to time to reflect updated content or information. Any claim shall be between you and the insurance agent whom you purchase the policy from, and coverage will be determined in accordance with the terms and conditions of the insurance policy and not the Site. Accordingly, you are encouraged to view/download a specimen of your actual policy documentation from the insurance agent prior to making any purchase decision.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* APPLICATION LICENSE */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">APPLICATION LICENSE</h2>
              <p className="text-gray-700">
                We grant you a limited, non-transferable, non-exclusive, and revocable permission to access and use the Services, provided that:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>You will not copy, distribute, or modify any part of the Services without our prior written authorization;</li>
                <li>You will not send unsolicited or unauthorized advertisements, spam, chain letters, etc.;</li>
                <li>You will not transmit any software viruses or other harmful computer code, files, or programs;</li>
                <li>You will not disrupt servers or networks connected to the Services; and</li>
                <li>You will comply with these Terms and KasiRenew’s policies from time to time.</li>
              </ol>
              <p className="text-gray-700">
                Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access, which shall remain with us and our respective licensors, as applicable.
              </p>
            </div>

            {/* KasiRenew RIGHTS */}
            <div className='mb-6'>
              <h2 className="text-2xl font-bold mb-4">KasiRenew RIGHTS</h2>
              <p className="text-gray-700">
                All right, title, and interest in and to Services including worldwide intellectual property rights therein, are and will remain the exclusive property of KasiRenew and its applicable licensors. We reserve all rights not expressly granted in and to the Services.
              </p>
            </div>
            {/* ACCOUNT INFORMATION */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">ACCOUNT INFORMATION</h2>
              <p className="text-gray-700">
                In order to access the Service, you will have to create an account. You hereby represent and warrant that the information you provide to KasiRenew upon registration (including information provided through your linked Facebook account, as applicable, or other third party Linked Accounts, as defined below), and at all other times, are true, accurate, current, and complete. Your "Account Information" also includes any information provided during the insurance application or claims process. You also hereby represent and warrant that you will ensure that this information is kept accurate and up-to-date at all times.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* PRIVACY AND PASSWORDS */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">PRIVACY AND PASSWORDS</h2>
              <p className="text-gray-700">
                KasiRenew values and protects the privacy of your information. Please review the KasiRenew Privacy Policy, as it contains important information relating to your use of the Site and Services.
              </p>
              <p className="text-gray-700">
                Some portions of the Site are protected and require a user identification code ("User ID") and pin/password for access. Unauthorized access or use of such portions of the Site is prohibited. You agree that you will notify KasiRenew immediately if you believe that a third party has obtained your User ID or password, or if you believe that any unauthorized access or use may occur or has occurred, by contacting our support team. For your protection, if KasiRenew believes that any unauthorized access may occur or has occurred, KasiRenew may terminate or suspend such access whether with or without prior notice to you. You also agree that KasiRenew is permitted to act upon any instructions received using your User ID and password and to consider such instructions as authorized by you.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* LINKED ACCOUNTS AND SOCIAL NETWORKING SITES */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">LINKED ACCOUNTS AND SOCIAL NETWORKING SITES</h2>
              <p className="text-gray-700">
                KasiRenew may, now or in the future, allow you to link your account on the Service to your account(s) on third party services, such as social networking sites ("Linked Accounts"). If you link your account on the Service to a Linked Account, you are authorizing KasiRenew to store and use your access credentials to access your Linked Account on your behalf to integrate your experience with the Service with content, information, and features available through such Linked Account. This may include importing the contacts, preferences, interests or "likes" of the Linked Account, and/or pushing updates regarding your use of the Service out to your Linked Accounts. Linking, accessing, or using a third-party service through the Service in this manner may be subject to additional terms established by the applicable third party, and it is your sole responsibility to comply with such third-party terms.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* UNDERWRITING */}
            <div className='mb-6'>
              <h2 className="text-2xl font-bold mb-4">UNDERWRITING</h2>
              <p className="text-gray-700">
                Your insurance or takaful is underwritten by your chosen insurance company or takaful operator. The underwriter and licensed insurance agencies available on the Site are as below respectively:
              </p>
              <ul className="list-disc ml-6 mb-4 text-gray-700">
                <li>
                  <strong>Insurer:</strong> Syarikat Takaful Malaysia Berhad<br />
                  <strong>Agency:</strong> Mutaqaddim Enterprise (002395478-U)
                </li>
                <li>
                  <strong>Insurer:</strong> Takaful Ikhlas General Berhad<br />
                  <strong>Agency:</strong> Mutaqaddim Enterprise (002395478-U)
                </li>
              </ul>
            </div>
            {/* INSURANCE QUOTES AND COVERAGES */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">INSURANCE QUOTES AND COVERAGES</h2>
              <p className="text-gray-700">
                All quotes generated on the Site from information by the insurance agencies are based upon the information you provide and are not a contract, binder, or agreement to extend insurance coverage. Any coverage descriptions available on the Site from information by the insurance agencies are general descriptions of available coverages and are not a statement of contract. To obtain coverage, you must submit an application to the insurance agency via the Site. All applications are between the user and the insurance agency and are subject to underwriting approval. Coverages and availability may vary by state or province; and additional minimum coverage limits may be available in your state depending on the agency.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Application for Insurance */}
            <div className="mb-6">
              <p className="text-gray-700">
                If you are applying for insurance from the insurance agency on the Site, you are reminded to take reasonable care not to make a misrepresentation in answering any and all questions in your application and to answer all questions fully and accurately. Failure to take reasonable care in answering the questions may result in avoidance of your contract of insurance or refusal or reduction of your claim(s) or change of terms or termination of your contract by the insurer. The above duty of disclosure continues until the time your contract of insurance is entered into, varied, or renewed with the insurer. In addition to answering the questions in your application with the insurance agency, you are required to disclose any other matter that you know to be relevant to the insurer’s decision in accepting the risks and determining the rates, and terms to be applied. You also have a duty to inform the insurance agent immediately if at any time after your contract of insurance has been entered into, varied, or renewed with the insurer, any of the information given to the insurance agent via the Site is inaccurate or has changed.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* CLAIMS SUBMISSIONS */}
            <div className='mb-6'>
              <h2 className="text-2xl font-bold mb-4">CLAIMS SUBMISSIONS</h2>
              <p className="text-gray-700">
                If you elect to report an insurance claim, the information you submit to the insurance agency via the Site regarding your insurance policy and the loss is subject to review and verification by the insurance agency. The insurance agency reserves the right to request additional information prior to submitting a claim to the insurance underwriter/company. A claim representative from the insurance underwriter/company may be communicating with you regarding your claim. If you have any questions concerning the coverage afforded by your policy, please visit the Site's support page to connect with your insurance agency.
              </p>
              {/* Add more paragraphs as needed */}
            </div>
            {/* POLICY RENEWAL AND REFUNDS */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">POLICY RENEWAL AND REFUNDS</h2>
              <p className="text-gray-700">
                When the insurance agency processes your insurance application, you are asked certain questions relating to your circumstances. The agency may request additional information to offer you a competitive price consistent with your circumstance and requirements. However, please be aware that such offered rate is subject to change suggested or imposed by the insurance company who underwrites your insurance policy. The site has no control over such changes that may occur from time to time. Any such changes shall be notified to you.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Refund Requests */}
            <div className="mb-6">
              <p className="text-gray-700">
                All refund requests are handled on a case-by-case basis by the relevant insurance agency. The agency reserves the right to make an investigation, and the duration of refund may vary for refund requests, if approved and paid. All refunds are at the discretion of the insurer, and the insurance agency will inform the user if his request is approved or not via the Site.
              </p>
              <p className="text-gray-700">
                There’s a processing fee not exceeding RM5 (unless notified otherwise) upon any order cancellation in accordance with the policy. KasiRenew reserves the right to impose administrative charges on any refund requests by the user to the insurance agency. If you feel you were incorrectly charged a processing fee, you may contact KasiRenew via the Help Centre on the Site for assistance.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* User Representations */}
            <div className="mb-6">
              <p className="text-gray-700">
                You represent and warrant that you have not been blacklisted or suspended by any regulator or authority (including Bank Negara, Securities Commission, Companies Commission of Malaysia, etc.) or underwriter, insurer, agent, or broker. Failure to inform the same to the insurance agent may result in rejection of any refund request, amongst others, and a suspension and/or ban on using the Site.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* Vouchers and Promotions */}
            <div className='mb-6'>
              <p className="text-gray-700">
                From time to time, marketing and promotional campaigns which offer voucher codes, discounts, subscription plans, or other promotional offers may be available on the Site (“Vouchers”). Vouchers are subject to validity periods, redemption periods, limits, and/or availability. Vouchers may not be valid when used in conjunction with other promotions, discounts, or other vouchers. Additional terms and conditions may apply to Vouchers. Unless otherwise stated, Vouchers can only be used on the Site. Vouchers are non-transferable, non-exchangeable, and non-refundable and cannot be exchanged for cash. KasiRenew reserves the right to withdraw, amend, and/or alter any part of the terms and conditions of the promotions and subscriptions at any time, and KasiRenew shall take reasonable efforts to notify via the Site, at any time, whether before, during, or after such promotion. KasiRenew reserves the right to void, discontinue, or disqualify any user from any such promotion if the user breaches any of these Terms or the terms of the Vouchers.
              </p>
              {/* Add more paragraphs as needed */}
            </div>
            {/* USER CONTENT GENERAL */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">USER CONTENT GENERAL</h2>
              <p className="text-gray-700">
                The Service may request that you submit content such as text, photos, audiovisual content, and other media content ("User Content"). By providing User Content to KasiRenew, you are granting KasiRenew a license to use the User Content in order to make it available through the Service.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* LICENSE GRANT BY YOU TO KasiRenew */}
            <div className='mb-6'>
              <h2 className="text-2xl font-bold mb-4">LICENSE GRANT BY YOU TO KasiRenew</h2>
              <p className="text-gray-700">
                By uploading the User Content, you are granting KasiRenew a license to display, perform, and distribute your User Content and to modify (for technical purposes), and reproduce such User Content to enable KasiRenew to operate the Service. You agree that these rights and licenses are royalty-free, worldwide, and irrevocable, and include a right for KasiRenew to make such User Content available to, and license others to use such User Content, for the purpose of providing such Services, and to otherwise permit access to or disclose your User Content to third parties if KasiRenew determines such access or disclosure is required or expedient to comply with its obligations.
              </p>
              {/* Add more paragraphs as needed */}
            </div>
            {/* SUBMITTED IDEAS POLICY */}
            <div>
              <h2 className="text-2xl font-bold mb-4">SUBMITTED IDEAS POLICY</h2>
              <p className="text-gray-700">
                When you submit any ideas, suggestions, documents, and/or proposals relating to the Service (or other products or services) to KasiRenew through the "Contact Us," User Forum, or Support interfaces or through any other channel or mechanism (collectively, "Contributions"), you acknowledge and agree that:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>The Contributions do not contain confidential or proprietary information;</li>
                <li>KasiRenew is not under any obligation of confidentiality, express or implied, with respect to the Contributions;</li>
                <li>KasiRenew shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way;</li>
                <li>KasiRenew may have something similar to the Contributions already under consideration or in development;</li>
                <li>The Contributions automatically become the property of KasiRenew; and</li>
                <li>You are not entitled to any accounting, compensation, or reimbursement of any kind from KasiRenew under any circumstances.</li>
              </ol>
              {/* Add more paragraphs as needed */}
            </div>
            {/* UNAUTHORIZED USE */}
            <div>
              <h2 className="text-2xl font-bold mb-4">UNAUTHORIZED USE</h2>
              <p className="text-gray-700">
                Do not misuse our Services. You agree not to use the Service or any aspect or feature thereof for any unlawful purpose or in any way that might harm, damage, or disparage any other party. Without limiting the preceding sentence, you agree that you will:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>Review and comply with these Terms and the Privacy Policy;</li>
                <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, export control laws, tax laws, and regulatory requirements;</li>
                <li>Provide accurate information to us and update it as necessary; and</li>
                <li>Act honestly and in good faith.</li>
              </ol>
              {/* Add more paragraphs as needed */}
            </div>
            {/* Prohibited Actions */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Prohibited Actions</h2>
              <p className="text-gray-700">
                By using the Services, you agree NOT to:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>Create an account for anyone other than a natural person (unless you are a company, organization, legal entity, or a brand and represent that company, organization, legal entity, or brand).</li>
                <li>Defame, stalk, bully, abuse, threaten, harass, abuse, intimidate, harm another person or engage in any other predatory behavior, including sending unwelcome communications to others or engaging in any other predatory behavior, or incite others to commit violent acts.</li>
                <li>Use or attempt to use another's account or create a false identity.</li>
                <li>Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform, display, sell, rebrand, or otherwise transfer information found in the Services, except as permitted in these Terms, or as expressly authorized by us.</li>
                <li>Reverse engineer, decompile, disassemble, decipher, or otherwise attempt to derive the source code for any underlying intellectual property used to provide the Services, or any part thereof.</li>
                <li>Utilize or copy information, content, or any data you view on or obtain from the Services to provide any service that is competitive, in our sole discretion, with the Services.</li>
                <li>Adapt, modify or create derivative works based on the Services or technology underlying the Services, in whole or part.</li>
                <li>Rent, lease, loan, trade, sell/re-sell any information in the Services, in whole or part.</li>
                <li>Use the communication systems provided by the Services for any commercial solicitation purposes.</li>
                <li>Sell, sponsor, or otherwise monetize any service or functionality in the Services, without our express written consent.</li>
                <li>Remove any copyright, trademark or other proprietary rights notices contained in or on the Services (whether ours or our licensors').</li>
                <li>Remove, cover, or otherwise obscure any form of advertisement included on the Services.</li>
                <li>Collect, harvest, use, copy, or transfer any information, including, but not limited to, personally identifiable information obtained from the Services (excluding your User Content, and except if the owner of such information has expressly permitted the same).</li>
                <li>Share other users' or third party's information or their User Content without their express consent.</li>
                <li>Infringe or use our brand, logos, or trademarks in any business name, email, or URL except as expressly permitted by us.</li>
                <li>Use or launch any manual or automated system or software, devices, scripts robots, other means or processes to access, "scrape," "crawl", "cache", "spider" or any web page or other service contained in our Services, or to access the Services in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser.</li>
                <li>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages, or perform other similar activities through the Services.</li>
                <li>Access, via automated or manual means or processes, the Services for purposes of monitoring its availability, performance or functionality for any competitive purpose.</li>
                <li>Engage in "framing," "mirroring," or otherwise simulating the appearance or function of the website.</li>
                <li>Attempt to or actually access the Services by any means other than through the interfaces provided by us. This prohibition includes accessing or attempting to access the Services using any third-party service, including software-as-a-service platforms that aggregate access to multiple services.</li>
                <li>Engage in any action that directly or indirectly interferes with the proper working of or places an unreasonable load on the Services' infrastructure, including, but not limited to, sending unsolicited communications to other users or our personnel, attempting to gain unauthorized access to the Services, or transmitting or activating computer viruses, Trojan horses, worms, time bombs or any other harmful or deleterious software programs through or on the Services.</li>
                <li>Interfere or disrupt or game the Services, including, but not limited to any servers or networks connected to the Services, or the underlying software.</li>
              </ol>
              {/* Add more paragraphs as needed */}
            </div>
            {/* NOTICES */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Notices</h2>
              <p className="text-gray-700">
                In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.
              </p>
              {/* Add more paragraphs as needed */}
            </div>

            {/* THIRD PARTIES' LINKS, WEBSITES, AND SERVICES */}
            <div className="mt-8 mb-6">
              <h2 className="text-2xl font-bold mb-4">Third Parties' Links, Websites, and Services</h2>
              <p className="text-gray-700">
                The Services may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. We are not affiliated with those websites, do not endorse them, have no control over those websites, and assume no responsibility and/or liability for the content, privacy policies, or practices of any third-party websites. In addition, we will not and cannot censor or edit the content of any third-party site.
              </p>
              <p className="text-gray-700">
                If you access any third party's website, service, or content from our Services or the Site, you do so at your own risk. By using the Services, you expressly release us (and our owners, employees, agents, affiliates, and/or licensors) from any and all liability arising from your use of any third-party website, information, materials, products, or services. Accordingly, we encourage you to be aware when you have left the Services and to read the terms and conditions and privacy policy of each other website that you visit.
              </p>
              {/* Add more paragraphs as needed */}
            </div>
            {/* DISCLOSURE */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Disclosure</h2>
              <p className="text-gray-700">
                We reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>Satisfy any applicable law, regulation, legal process, subpoena, or governmental request.</li>
                <li>Enforce these Terms, including investigation of potential violations of it.</li>
                <li>Detect, prevent, or otherwise address fraud, security, or technical issues.</li>
                <li>Cooperate with law enforcement authorities.</li>
                <li>Respond to user support requests.</li>
                <li>Protect our, our users', or the public's rights, property, or safety.</li>
              </ol>
              {/* Add more paragraphs as needed */}
            </div>
            {/* LIMITATION OF LIABILITY */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Limitation of Liability</h2>
              <p className="text-gray-700">
                To the maximum extent permitted by law, the Service Is Available "As Is." You expressly understand and agree that:
              </p>
              <ol className="list-decimal ml-6 mb-4 text-gray-700">
                <li>Your use of the service and the purchase and use of any products or services are all at your sole risk. The service is provided and products are sold on an "as is" and "as available" basis. To the maximum extent permitted by law, KasiRenew expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement.</li>
                <li>KasiRenew does not warrant that:
                  <ol className="list-decimal ml-6">
                    <li>The service will meet all of your requirements;</li>
                    <li>The service will be uninterrupted, timely, secure or error-free; or</li>
                    <li>All errors in the software or service will be corrected.</li>
                  </ol>
                </li>
                <li>Any material downloaded or otherwise obtained through the use of the service is done at your own discretion and risk, and you are solely responsible for any damage to your computer or other device or loss of data resulting from the download or use of any such material.</li>
                <li>No information, whether oral or written, obtained by you from KasiRenew shall create any warranty not expressly stated in these Terms.</li>
                <li>You expressly understand and agree that KasiRenew, its subsidiaries, affiliates, and licensors, and our and their respective officers, employees, agents, and successors shall not be liable to you for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, cover, or other intangible losses (even if KasiRenew has been advised of the possibility of such damages) resulting from:
                  <ol className="list-decimal ml-6">
                    <li>The use or the inability to use the service;</li>
                    <li>Unauthorized access to or the loss, corruption, or alteration of your transmissions, content, or data;</li>
                    <li>Statements or conduct of any third party on or using the service, or providing any services related to the operation of the service;</li>
                    <li>KasiRenew's actions or omissions in reliance upon your account information and any changes thereto or notices received therefrom;</li>
                    <li>Your failure to protect the confidentiality of any passwords or access rights to your account information;</li>
                    <li>The acts or omissions of any third party using or integrating with the service;</li>
                    <li>The termination of your account in accordance with these Terms;</li>
                    <li>Reliance on the information contained in the Site;</li>
                    <li>Any technical, hardware, or software failure of any kind or any interruption, error, omission, delay in operation, computer viruses, or otherwise; or</li>
                    <li>Any other matter relating to the service;</li>
                  </ol>
                unless caused by our willful default or fraud.</li>
                <li>In any event and without limiting the generality of this section, you agree that our total liability to you for any and all damages and losses shall not in any circumstances exceed the greater of (a) RM100, or (b) the aggregate of the amount (if any) paid by you for the services in the 6 months immediately preceding bringing of a claim against us or our affiliates. Nothing in these Terms (including the limitation of liability provisions) is intended to exclude or limit any condition, warranty, right, or liability which may not be lawfully excluded or limited.</li>
              </ol>
              {/* Add more paragraphs as needed */}
            </div>
            {/* ASSIGNMENT */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Assignment</h2>
              <p className="text-gray-700">
                KasiRenew reserves the right, in its sole and absolute discretion, to transfer, assign, sublicense, or pledge in any manner whatsoever, any of its rights and obligations under these Terms to a subsidiary, affiliate, successor thereof, or to any third party whatsoever, without your prior notice, provided that KasiRenew shall provide you reasonable notice thereafter, if it has not already done so. You shall not transfer, assign, delegate, sublicense nor pledge in any manner whatsoever, any of your rights or obligations under these Terms without KasiRenew’s prior written consent.
              </p>
            </div>

            {/* INDEMNITY */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">Indemnity</h2>
              <p className="text-gray-700">
                You agree to defend, indemnify and hold harmless KasiRenew, our affiliates, and our and their respective owners, officers, directors, employees, agents, and/or licensors, from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses (including but not limited to attorney's fees) arising from:
              </p>
              <ol className="list-decimal ml-6">
                <li>Your use of the Services;</li>
                <li>Your violation of these Terms;</li>
                <li>Your violation of any third-party right, including without limitation any copyright, property, publicity or privacy right; including all actions taken under your account.</li>
              </ol>
            </div>

            {/* GOVERNING LAW AND JURISDICTION */}
            <div className="mt-8 mb-6">
              <h2 className="text-2xl font-bold mb-4">Governing Law and Jurisdiction</h2>
              <p className="text-gray-700">
                These Terms, the Services, and your use of the Site are governed in all respects by the laws of Malaysia. You submit to the jurisdiction of the Malaysian courts.
              </p>
            </div>
            {/* GENERAL */}
            <div>
              <h2 className="text-2xl font-bold mb-4">General</h2>
              <p className="text-gray-700">
                We reserve the right to discontinue or modify any aspect of the Services at any time. These Terms, together with the Privacy Policy, KasiRenew’s policies and any other legal notices published by us on the Services, shall constitute the entire agreement between us concerning the Services. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term by KasiRenew shall be deemed a further or continuing waiver of such term or any other term, and a KasiRenew's failure or delay to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
              </p>
            </div>

            {/* ELECTRONIC COMMUNICATION */}
            <div className="mt-8 mb-6">
              <h2 className="text-2xl font-bold mb-4">Electronic Communication</h2>
              <p className="text-gray-700">
                By using the Site, you agree to have all records, including the insurance policy by the insurance agent, provided to you in electronic form. This consent, unless withdrawn, applies to all transactions on the Site. However, you have the right to receive records, including the insurance policy, in paper form if you wish by withdrawing this consent.
              </p>
              <p className="text-gray-700">
                If you withdraw the consent to provide you with records in electronic form, you will then be provided with records in paper form. To withdraw your consent, you must email us at help@KasiRenew.my with the following subject line "WITHDRAW ELECTRONIC CONSENT." The body of the email must include your name, policy number, effective and expiration dates of the policy, the effective date of your withdrawal and whether you want (a) any specific record to be in paper form and (b) your insurance policy to be sent to you in paper form.
              </p>
              <p className="text-gray-700">
                KasiRenew reserves the right to charge a delivery and printing fee for paper transmission of the insurance policy, any invoice and any policy endorsement or record requested by you. You may also choose to print the records in electronic form from your end.
              </p>
            </div>
            {/* COPYRIGHT */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Copyright</h2>
              <p className="text-gray-700">
                Unless otherwise indicated, the copyright in this website and its contents, including but not limited to the text, images, graphics, sound files, animation files, video files, and their arrangement, are the property of KasiRenew, and are protected by applicable Malaysian and international copyright laws. No part or parts of this website may be modified, copied, distributed, retransmitted, broadcast, displayed, performed, reproduced, published, licensed, transferred, sold or commercially dealt with in any manner without the express prior written consent of KasiRenew.
              </p>
              <p className="text-gray-700">
                You shall not, without KasiRenew’s prior written consent, insert a link to this website on any other website, frame or "mirror" any material contained on this website on any server. Any such unauthorized reproduction, retransmission or other copying or modification of any of the contents of KasiRenew’s website may be in breach of law, in addition to being a breach of these Terms. KasiRenew disclaims all liability which may arise from any unauthorized reproduction or use of the contents of our website.
              </p>
            </div>

            {/* TRADEMARKS */}
            <div className="mt-8 mb-6">
              <h2 className="text-2xl font-bold mb-4">Trademarks</h2>
              <p className="text-gray-700">
                All trademarks, service marks, and logos displayed in this website are either the property of KasiRenew or KasiRenew has obtained the rights to use them. Unless the prior written consent of KasiRenew has been obtained, no license or right is granted to any party accessing the Site to use, download, reproduce, copy or modify such trademarks, services marks or logos.
              </p>
              <p className="text-gray-700">
                Similarly, unless the prior written consent of KasiRenew or the relevant proprietor has been obtained, no such trademark, service mark or logo may be used as a link or to mark any link to KasiRenew’s Site or any other site.
              </p>
            </div>
            {/* EXCLUSION OF LIABILITY */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Exclusion of Liability Due to Acts and/or Omission by Any Third Party / Relevant Authority(ies)</h2>
              <p className="text-gray-700">
                KasiRenew shall not be responsible or liable for any claim, loss of profits, revenues, or data or any financial losses or any direct or indirect, special, consequential, exemplary, or punitive damages caused by any third party/relevant authority(ies) including but not limited to the following instances:-
              </p>
              <ul className="list-disc list-inside text-gray-700">
                <li>Discretion exercised by any third party/relevant authority(ies);</li>
                <li>Closing/cancellation of programs by any third party/relevant authority(ies);</li>
                <li>Delay on the part of any third party/relevant authority(ies) in processing your application; and</li>
                <li>Failure to comply with the requirement set by any third party/relevant authority(ies) by you.</li>
              </ul>
            </div>

            {/* INDEMNITY */}
            <div className="mt-8 mb-6">
              <h2 className="text-2xl font-bold mb-4">Indemnity</h2>
              <p className="text-gray-700">
                You hereby irrevocably agree to indemnify and keep indemnified KasiRenew from all liabilities, claims, losses and expenses, including any legal fees that may be incurred by KasiRenew in connection with or arising from:
              </p>
              <ol className="list-decimal list-inside text-gray-700">
                <li>Your use or misuse of this website and the services provided herein,</li>
                <li>Your breach of these Terms, or</li>
                <li>Any intellectual property right or proprietary right infringement claim made by a third party against KasiRenew in connection with your use of this website.</li>
              </ol>
            </div>
            {/* MODIFYING AND TERMINATING OUR SERVICES */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Modifying and Terminating Our Services</h2>
              <p className="text-gray-700">
                We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether. You can stop using our Services at any time, although we’ll be sorry to see you go. KasiRenew may also stop providing Services to you, or add or create new limits to our Services at any time. We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service. KasiRenew reserves the right to terminate and/or suspend your access to the Site and/or your use of the Site at any time, for any reason. In particular, and without limitation, KasiRenew may terminate and/or suspend your access should you violate any of these Terms, or violate the rights of KasiRenew, of any other user, or of any third party.
              </p>
            </div>

            {/* BUSINESS USES OF THE SERVICES */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">Business Uses of the Services</h2>
              <p className="text-gray-700">
                If you are using our Services on behalf of a business or a company, that business or a company accepts these Terms. It will hold harmless and indemnify KasiRenew and its service suppliers, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
              </p>
            </div>

            {/* ABOUT THESE TERMS */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">About These Terms</h2>
              <p className="text-gray-700">
                We may modify these terms or any additional terms or policy that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at these Terms when you access the Service. KasiRenew reserves the right to amend any of the Terms and its policies at any time without any prior notice.
              </p>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tnc;
