import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import shopeePayIcon from '../../img/shopeepay-logo.png';
import qrcode from '../../img/qrcode.jpg';

function ShopeePayPayment({ totalPrice }) {
  // Add any ShopeePay-specific calculations here using the 'totalPrice' prop
  const shopeepay6 = (totalPrice + ((totalPrice*9)/100))/6;
  const shopeepay12 = Math.round((shopeepay6 * 2) * 10) / 10; // Replace with your calculations
  const cajShopeepay = Math.round((shopeepay12 - totalPrice) * 10) / 10; // Replace with your calculations
  
  const sendLink = (whatsapp_no) => {
    // const message = `Terima%20kasih%20kerana%20renew%20insuran%20kenderaan%20di%20KasiRenew.com.%20Disini%20saya%20attach%20cover-note%20insuran%20kenderaan%20Tn%2FPn.%0D%0A%0D%0ATn%2FPn%20nak%20renew%20Roadtax%20sekali%3F`;
    const whatsappLink = `https://wa.me/601120888930`;
 
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div>
      {/* <img src={shopeePayIcon} alt="ShopeePay" className="h-12 mx-auto mb-4" /> */}
      <p className='text-center'>Buka aplikasi Shopee atau Boost dan scan QR Code dibawah.</p>
      <p className='text-center'>Kemudian, pilih Spaylater atau Boost PayFlex untuk pembayaran secara ansuran.</p>

      <img src={qrcode} alt="QRCode" className="mx-auto my-8" />

      <p className='text-center'>Setelah selesai membuat pembayaran, hantar bukti pembayaran ke WhatsApp kami dengan klik butang dibawah:</p>
      
      <div className="text-center mt-6">
        <button
          onClick={() => sendLink()}
          className="bg-green-500 text-white p-3 rounded-full flex items-center mx-auto"
        >
          <FaWhatsapp className="text-2xl mr-4" /> WhatsApp Kami
        </button>
      </div>
      
      
      
      {/* <p className="text-xl text-center mt-4">Ansuran (6 bulan):</p>
      <p className="text-xl font-bold text-center mb-4">RM{shopeepay6.toFixed(2)} x 6</p>
      <p className="text-xl text-center mt-4">Ansuran (12 bulan):</p>
      <p className="text-xl font-bold text-center mb-4">RM{shopeepay12.toFixed(2)} x 12</p>
      <p className="text-center mb-4">*Faedah sebanyak 1.5% setiap bulan akan dikenakan.</p>
      <p className='text-center'>*Caj perkhidmatan sebanyak RM{cajShopeepay.toFixed(2)} akan dikenakan jika anda memilih kaedah pembayaran ini.</p> */}
    </div>
  );
}

export default ShopeePayPayment;
