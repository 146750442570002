import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import takafulmalaysia from '../img/takafulmalaysia.png';
import takafulikhlas from '../img/takafulikhlas.png';
import PricingFeaturesTM from '../components/PricingFeaturesTM';
import PricingFeaturesTI from '../components/PricingFeaturesTI';
import axios from 'axios'; // Import Axios library
import { useParams } from 'react-router-dom'; // Import useParams
import { useNavigate } from 'react-router-dom';
import ThirdPartyFeaturesTI from '../components/3rdPartyFeaturesTI';

function Pricing({ match }) {
  const [includeRoadtax, setIncludeRoadtax] = useState(false);
  const [data, setData] = useState(null);
  const { token } = useParams(); // Get the 'token' parameter from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Make an API request to fetch data based on the token
    axios.get(`https://api.kasirenew.com/api/quotation/${token}`)
      .then((response) => {
        if (response.status === 404) {
          throw new Error('Quotation not found');
        }
        return response.data; // Use response.data to get the JSON data
      })
      .then((responseData) => {
        setData(responseData);
        localStorage.setItem('client_id', responseData.client.id);
        console.log(responseData.client.id);
      })
      .catch((error) => {
        console.error(error);
        // Handle error or display a message to the user
      });
  }, [token]); // Include 'token' in the dependency array

  // Render the component based on the fetched data
  if (!data) {
    return (
      <div className="h-screen flex items-center justify-center bg-purple-600">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-200"></div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const monthsInMalay = [
      'Januari', 'Februari', 'Mac', 'April', 'Mei', 'Jun', 'Julai',
      'Ogos', 'September', 'Oktober', 'November', 'Disember'
    ];
  
    if (dateString && typeof dateString === 'string') {
      const [year, month, day] = dateString.split('-');
      if (year && month && day) {
        const formattedDate = `${day} ${monthsInMalay[parseInt(month, 10) - 1]} ${year}`;
        return formattedDate;
      }
    }
  
    // Return a default value or handle the error case as needed
    return 'Invalid Date';
  };

  const {
    client_id = data.client.id,
    id_number = data.client.id_number,
    plate_no = data.client.plate_no,
    from_date = data.quotation.from_date,
    to_date = data.quotation.to_date,
    takaful_options = data.quotation.takaful_options,
    party_options = data.quotation.party_options,
    price = data.quotation.price,
    windscreen = data.quotation.windscreen,
    roadtax = data.quotation.roadtax,
    market_value = data.quotation.market_value,
    sum_cover = data.quotation.sum_cover,
    ncd = data.quotation.ncd,
  } = data;

  const maskedPart = 'XXXXXX-XX-';

  // Calculate the total price based on roadtax inclusion
  const totalPriceTemp = includeRoadtax ? parseFloat(price) + parseFloat(roadtax) : parseFloat(price);
  const totalPrice = totalPriceTemp.toFixed(2);
  const atome = Math.round(((totalPrice / 0.94 + 1) / 3) * 10) / 10; // Round to the nearest tenth

  // Define the image source based on takaful_options
  const takafulImgSrc = takaful_options === 'Takaful Malaysia' ? takafulmalaysia : takafulikhlas;

  const handleRenew = () => {
    setLoading(true);
    const client_id = localStorage.getItem('client_id'); // Assuming you've already set client_id
    if (!client_id) {
      console.error('Client ID not found in local storage');
      return;
    }

    // Check the 'status' from the client API
    axios.get(`https://api.kasirenew.com/api/clients/${client_id}`)
      .then((response) => {
        const status = response.data.status;

        if (status === 'paid' || status === 'completed') {
          // If 'status' is 'paid', redirect to 'payment/success'
          window.location.href = 'https://kasirenew.com/payment/success';
        } else {
          // If 'status' is not 'paid', continue with the logic
          localStorage.setItem('client_id', client_id);

          // Create a payload with the required data
          const payload = {
            client_id: client_id, // Use the client_id from your data
            price: price,
            roadtax: includeRoadtax ? roadtax : 0,
          };

          // Check if data with 'client_id' exists in 'cart'
          axios.get(`https://api.kasirenew.com/api/cartclient/${client_id}`)
            .then((response) => {
              const cartItem = response.data.client_id;

              if (cartItem) {
                // If data exists, update it
                axios.put(`https://api.kasirenew.com/api/updatecart/${client_id}`, payload)
                  .then((response) => {
                    console.log('Data updated:', response.data);
                    // Navigate to the checkout page
                    navigate('/checkout');
                  })
                  .catch((error) => {
                    console.error('Error updating data:', error);
                  });
              } else {
                // If data doesn't exist, add it
                axios.post('https://api.kasirenew.com/api/cart', payload)
                  .then((response) => {
                    console.log('Data added:', response.data);
                    // Navigate to the checkout page
                    navigate('/checkout');
                  })
                  .catch((error) => {
                    console.error('Error adding data:', error);
                  });
              }
            })
            .catch((error) => {
              console.error('Error checking data:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error checking client status:', error);
      });
  };

  

  return (
    <div>
      <Header />
      <div className="bg-gray-100 min-h-screen mt-12 py-16 px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="container mx-auto">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">
            Sebut Harga
          </h1>
          <div className="p-6 max-w-md mx-auto text-center">
            <p>
              NRIC: <strong>{maskedPart + id_number.slice(-4)}</strong>
            </p>
            <p>
              No Pendaftaran: <strong className="uppercase">{plate_no}</strong>
            </p>
            <p>
              Tarikh sah: <strong>{formatDate(from_date)}</strong> - <strong>{formatDate(to_date)}</strong>
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
            <img
              className="h-14 mx-auto my-5"
              src={takafulImgSrc}
              alt={takaful_options}
            />
            <h2 className="text-2xl font-semibold text-center">
              {takaful_options}
            </h2>
            <h3 className="text-xl text-center mb-4">{party_options}</h3>
            <div className="text-4xl font-semibold text-center mb-3">
              RM{totalPrice}
            </div>
            {/* <div className="text-xl font-semibold text-gray-700 text-center mb-6">
              Atome (ansuran): RM{atome.toFixed(2)} x 3
            </div> */}
            <p className="text-center">
              NCD: <strong>{ncd}%</strong>
            </p>
            <p className="text-center">
              Windscreen Cover: <strong>RM{windscreen}</strong>
            </p>
            <p className="text-center">
              Market Value: <strong>RM{market_value}</strong>
            </p>
            <p className="text-center">
              Sum Cover: <strong>RM{sum_cover}</strong>
            </p>
            {/* <label className="flex items-center m-10">
              <input
                type="checkbox"
                checked={includeRoadtax}
                onChange={() => setIncludeRoadtax(!includeRoadtax)}
                className="mr-2 h-5 w-5 text-green-500"
              />
              Include Roadtax (1 tahun): RM{roadtax} <br />(Hanya Tunai & Spaylater sahaja.)
            </label>
            <p>*Roadtax dibuat secara online. Tiada lagi kertas roadtax bermula 11/7/2023</p> */}
            {party_options === 'Comprehensive' ? (
            <div>
              {takaful_options === 'Takaful Malaysia' ? (
                <PricingFeaturesTM />
              ) : (
                <PricingFeaturesTI />
              )}
            </div>
            ) : (
              <div><ThirdPartyFeaturesTI /></div>
            )}
            <div className="text-center mt-6">
              <button
                className="flex items-center mx-auto bg-purple-500 text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
                onClick={handleRenew}
              >
                <p className='mr-2'>Renew Sekarang</p>
                {loading && (
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-200"></div>
                )}
              </button>
            </div>
            <div class="bg-gray-100 p-8 mt-12">
              <div class="max-w-lg mx-auto">
                <h2 class="text-2xl font-semibold mb-4">Ingin perbaharui Roadtax dengan kami? Ini perkara yang anda perlu tahu:</h2>
                <ul class="list-disc pl-6 mb-4">
                  <li class="mb-2">Kami hanya menerima <strong>Tunai</strong> dan <strong>Spaylater</strong> sahaja untuk <strong>Roadtax</strong> (kaedah pembayaran yang lain seperti FPX, eWallet, Grab Paylater hanya untuk <strong>insuran</strong> sahaja)</li>
                  <li class="mb-2">Roadtax dibuat secara online. <strong>Tiada lagi kertas roadtax</strong> bermula 11/7/2023</li>
                  <li>Caj servis sebanyak <strong>RM5.00</strong> akan dikenakan untuk perbaharui cukai jalan dengan kami</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricing;