import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaWhatsapp, FaCheck, FaBell, FaMoneyBill } from 'react-icons/fa';

const RefundPolicy = () => {
  const steps = [
    { icon: <FaWhatsapp size={100} />, title: 'Hubungi Kami', description: 'Hubungi kami melalui WhatsApp untuk memohon pemulangan wang.' },
    { icon: <FaCheck size={100} />, title: 'Pemulangan Diproses', description: 'Setiap permohonan akan disahkan dan diproses dalam 24 jam.' },
    { icon: <FaBell size={100} />, title: 'Terima Notifikasi', description: 'Anda akan menerima notifikasi melalui WhatsApp. Pemulangan wang akan diproses oleh bank.' },
    { icon: <FaMoneyBill size={100} />, title: 'Terima Bayaran', description: 'Anda akan menerima pemulangan wang dalam 7 - 14 hari bekerja.' },
  ];

  const faqs = [
    {
      question: 'Roadtax saya tidak boleh diperbaharui kerana telah disenarai hitam oleh JPJ/ PDRM. Adakah saya boleh mendapatkan bayaran balik wang?',
      answer: 'Ya. Anda akan menerima bayaran balik wang untuk pembayaran roadtax anda.',
    },
    {
      question: 'Polisi insurans saya mempunyai harga yang lebih rendah dari caj yang dikenakan. Adakah saya akan mendapat bayaran balik wang?',
      answer: 'Ya. Jika polisi sebenar anda mempunyai harga yang lebih rendah, kami akan maklumkan kepada anda melalui e-mel untuk menghantar permintaan bayaran balik.',
    },
    {
      question: 'Berapa lamakah masa yang akan diambil?',
      answer: 'Anda akan menerima pengesahan untuk pembayaran balik wang dalam tempoh 7 - 14 hari bekerja.',
    },
    {
      question: 'Adakah ada sebarang kos pemprosesan akan dikenakan?',
      answer: 'Untuk lebihan bayaran balik, tiada sebarang kos pemrosesan akan dikenakan. Untuk pembatalan pembelian insurans dan roadtax, kos pemprosesan sebanyak RM5 akan dikenakan.',
    },
    {
      question: 'Saya ingin membatalkan insurans yang masih aktif. Bagaimanakah saya boleh mendapatkan bayaran balik wang?',
      answer: 'Sebaik sahaja insurans anda aktif, permintaan bayaran balik akan diproses oleh syarikat insurans mengikut kesesuaian di bawah Akta Insurans 1996. Kami akan membantu anda untuk menghantar permintaan bayaran balik kepada syarikat insurans yang berkenaan.',
    },
  ];

  return (
    <div>
      <Header />
      <div className="py-24 px-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Polisi Pemulangan Wang</h1>

        <div className="mt-8 mx-auto bg-white py-8 px-8 md:px-16 rounded shadow">
          <h1 className="text-3xl mb-6 text-center">Proses Pemulangan Wang</h1>

          <div className="flex flex-wrap -mx-4 mt-8">
            {steps.map((step, index) => (
              <div key={index} className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                <div className="text-center">
                  <div className="text-blue-500 mb-2 flex items-center justify-center">{step.icon}</div>
                  <h2 className="text-lg font-bold mb-2">{step.title}</h2>
                  <p className="text-gray-700">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-8 mx-auto bg-white py-8 px-8 md:px-16 rounded shadow">
          <h1 className="text-3xl mb-6 text-center">Soalan Lazim</h1>
          {faqs.map((faq, index) => (
            <div key={index} className="mb-4">
              <h3 className="text-lg font-bold mb-2">{faq.question}</h3>
              <p className="text-gray-700">{faq.answer}</p>
            </div>
          ))}
        </div>

        <div className="mt-8 mx-auto bg-white py-8 px-8 md:px-16 rounded shadow">
          <h1 className="text-3xl mb-6 text-center">Hubungi Kami</h1>
          <p className="text-gray-700 text-center">
            Jika ada apa-apa pertanyaan berkenaan polisi pemulangan wang, boleh hubungi kami menerusi WhatsApp dengan klik butang berikut:
          </p>
          <div className="flex justify-center mt-8">
            <a
              href="https://wa.me/601120888930"
              target="_blank"
              rel="noopener noreferrer"
              className="px-5 py-3 rounded-lg bg-purple-600 hover:bg-purple-700 text-white"
            >
              WhatsApp Kami
            </a>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
