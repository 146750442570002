import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FailedMessage from '../components/messages/FailedMessage';
import FloatingActionButton from '../components/FloatingActionButton';

function Failed() {

  return (
    <div>
      <Header />
      <FailedMessage />
      <Footer />
      <FloatingActionButton />
    </div>
  );
}

export default Failed;
