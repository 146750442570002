import React from 'react';
import logo from '../img/logo.png'; // Adjust the path to your logo image

function Header() {
  return (
    <header className="fixed top-0 left-0 w-full bg-purple-600 shadow-md py-4 z-10">
      <div className="flex items-center justify-between px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-10' /></a>
      </div>
    </header>
  );
}

export default Header;
