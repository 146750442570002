// SuccessMessage.js

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const SuccessMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-8 container mx-auto">
      <div className="text-6xl text-green-500">
        <FaCheckCircle size={100} />
      </div>
      <div className="text-2xl text-green-500 mt-4">Berjaya!</div>
      <div className="text-lg text-center text-gray-600 mt-2">Pembayaran anda telah berjaya. Kami akan menghantar cover note takaful kenderaan anda dengan segera.</div>
    </div>
  );
};

export default SuccessMessage;
