import React from "react";

const ClientInfo = ({ id_number, plate_no, from_date, to_date }) => {

  const formatDate = (dateString) => {
    const monthsInMalay = [
      'Januari', 'Februari', 'Mac', 'April', 'Mei', 'Jun', 'Julai',
      'Ogos', 'September', 'Oktober', 'November', 'Disember'
    ];
  
    if (dateString && typeof dateString === 'string') {
      const [year, month, day] = dateString.split('-');
      if (year && month && day) {
        const formattedDate = `${day} ${monthsInMalay[parseInt(month, 10) - 1]} ${year}`;
        return formattedDate;
      }
    }
  
    // Return a default value or handle the error case as needed
    return 'Invalid Date';
  };

  const maskedPart = 'XXXXXX-XX-';

  return(
    <div>
      <div className="p-6 max-w-md mx-auto text-center">
        <p>
          NRIC: <strong>{maskedPart + id_number.slice(-4)}</strong>
        </p>
        <p>
          No Pendaftaran: <strong className="uppercase">{plate_no}</strong>
        </p>
        <p>
          Tarikh sah: <strong>{formatDate(from_date)}</strong> - <strong>{formatDate(to_date)}</strong>
        </p>
      </div>
    </div>
  );
};

export default ClientInfo;