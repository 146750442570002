import React from 'react';
import { RiFacebookFill, RiTwitterFill, RiInstagramFill, RiTiktokFill } from 'react-icons/ri';
import logo from '../img/logo.png';

function Footer() {
  return (
    <footer className="bg-purple-500 text-white py-8 text-center">
      <div className="container mx-auto">
        <div className="flex justify-center space-x-4">
          <a
            href="#"
            className="text-white hover:text-purple-300 text-2xl"
          >
            <RiFacebookFill />
          </a>
          <a
            href="#"
            className="text-white hover:text-purple-300 text-2xl"
          >
            <RiTwitterFill />
          </a>
          <a
            href="#"
            className="text-white hover:text-purple-300 text-2xl"
          >
            <RiInstagramFill />
          </a>
          <a
            href="#"
            className="text-white hover:text-purple-300 text-2xl"
          >
            <RiTiktokFill />
          </a>
        </div>
        <div className="flex justify-center space-x-4 mt-8">
          <a
            href="/terms-and-conditions"
            className="text-white hover:text-purple-300"
          >
            Terma & Syarat
          </a>
          <a
            href="/refund-policy"
            className="text-white hover:text-purple-300"
          >
            Polisi Pemulangan Wang
          </a>
          <a
            href="https://info.kasirenew.com/privacy-policy-2/"
            className="text-white hover:text-purple-300"
          >
            Privacy Policy
          </a>
          <a
            href="/contact-us"
            className="text-white hover:text-purple-300"
          >
            Hubungi Kami
          </a>
        </div>
        <img
          src={logo} // Update the path to your logo
          alt="KasiRenew.com"
          className="h-10 mx-auto mt-5"
        />
      </div>
    </footer>
  );
}

export default Footer;
