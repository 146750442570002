import React from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';

function FeaturesThirdParty() {
    return (
        <div>
            <ul className="text-md mb-8">
              <li className="flex items-center mb-2">
                <RiCheckboxCircleFill className="h-5 w-5 mr-2 text-white" />
                <span className="flex-1">
                  Melindungi kebakaran dan kecurian kenderaan.
                </span>
              </li>
            </ul>
        </div>
    );
}

export default FeaturesThirdParty;